
.clf-home-page {
    position: relative;
}

/* fullcalendar v5 header custom */
.headerV5 .fc-col-header-cell-cushion {
    width: 100%;
    padding: unset;
}

.headerV5 {
    background: #F1F1F1;
}

.slotV5 {
    border: 1px solid #FFF;
}

.slotLaneV5 {
    border: 1px solid #FFF;
}

.fc th, .fc td {
    border-color: #FFFFFF;
}

.fc-theme-standard .fc-scrollgrid {
    border-color: #FFFFFF;
}

.fc-event-main {
    overflow: hidden;
}

.fc-timegrid-axis {
    background: #F1F1F1;
}

.fontMontserrat {
    font-family: 'Montserrat', sans-serif !important;
}

.MuiTypography-root {
    font-family: 'Montserrat', sans-serif !important;
}

.fontBebasNeue {
    font-family: 'Bebas Neue', cursive;
}

.isSun {
    color: #ff867f;
}

.isSat {
    color: #1B97F0;
}

.isWorkingDay {
    color: #000;
}

.headerCalendar {
    font-weight: bold;
    position: relative;
    border: solid 1px transparent;
    cursor: pointer;
}

.isSelection {
    border: solid 1px red;
}

.material-icons-fix {
    vertical-align: middle;
}


.scrollLeftEvent {
    overflow: hidden;
    position: fixed;
    left: 0;
    bottom: 0;
    top: 84px;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
    padding: 0px 10px 10px 0;
    /*background: #f7f7f7;*/
    box-sizing: border-box;
}

.leftEventMobile {
    position: static;
    min-width: auto;
}

.scrollLeftEvent:hover {
    overflow: auto;

}

.scrollLeftEvent::-webkit-scrollbar {
    display: none
}

.contentEvent {
    overflow-y: hidden;
    margin-left: 16.666667%;
    overflow-x: auto;
}

.contentEventMobile {
    margin-left: 0px;
    overflow: auto;
}

.hiddenDisplay {
    display: none;
}

.check-free {
    position: absolute;
    bottom: 1px;
    left: 50%;
    transform: translateX(-50%);
}

.event-hover .check-free-noneClick {
    opacity: 0.4;
    position: absolute;
    bottom: 1px;
    left: 50%;
    transform: translateX(-50%);
}

.event-hover .check-free-noneClick :hover {
    opacity: 0.4;
}


.hover-show-tooltip .ant-tooltip-inner {
    background-color: #F1F1F1;
    border-radius: 10px;
    padding: 5px;
    /*box-shadow: 1px 1px 3px 3px #9d9b9b;*/
}

.hover-show-tooltip .ant-tooltip-arrow-content {
    background-color: #F1F1F1;
}

.hover-show-tooltip .ant-tooltip-content {
    min-width: 180px;
}

.hover-show-tooltip-2 .ant-tooltip-content {
    min-width: 100px !important;
}


@media screen and (max-width: 1400px) {
    .scrollLeftEvent {
        min-width: 260px;
    }

    .contentEvent {
        margin-left: 260px;
    }
}


@media screen and (max-width: 600px) {
    .leftEventMobile, .scrollLeftEvent {
        position: static;
        min-width: auto;
    }

    .contentEvent {
        margin-left: 0px;
    }

    .timeCalendar {
        flex-direction: column;
    }

}
